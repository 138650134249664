body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  color-scheme: dark;
}

.timeline-section{
	background-color: #24292d;
	min-height: 100vh;
	padding: 10px 15px;
  font-family: Montserrat;
}
.timeline-items{
	max-width: 1000px;
	margin:auto;
	display: flex;
	flex-wrap: wrap;
	position: relative;
}
.timeline-items::before{
	content: '';
	position: absolute;
	width: 2px;
	height: 100%;
	background-color: #2f363e;
	left: calc(50% - 1px);
}
.timeline-item{
	margin-bottom: 40px;
	width: 100%;
	position: relative;
}
.timeline-item:last-child{
	margin-bottom: 0;
}
.timeline-item:nth-child(odd){
    padding-right: calc(50% + 30px);
	text-align: right;
}
.timeline-item:nth-child(even){
    padding-left: calc(50% + 30px);
}
.timeline-dot{
	height: 16px;
	width: 16px;
	background-color: #eaa023;
	position: absolute;
	left: calc(50% - 8px);
	border-radius: 50%;
	top:10px;
}

.timeline-date{
	font-size: 18px;
	color: #eaa023;
	margin:6px 0 15px;
}
.timeline-content{
    background-color: #2f363e;
	padding: 30px;
	border-radius: 5px;
}
.timeline-content h3{
    font-size: 20px;
	color: #ffffff;
	margin:0 0 10px;
	text-transform: capitalize;
	font-weight: 500;
}
.timeline-content p{
    color: #c8c8c8;
	font-size: 16px;
	font-weight: 300;
	line-height: 22px;
}

/* responsive */
@media(max-width: 767px){
	.timeline-items::before{
		left: 7px;
	}
	.timeline-item:nth-child(odd){
		padding-right: 0;
		text-align: left;
	}
	.timeline-item:nth-child(odd),
	.timeline-item:nth-child(even){
		padding-left: 37px;
	}
	.timeline-dot{
		left:0;
	}
}


